@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes kenburns {
  0% {
    transform: scale(1) translate(0, 0);
  }
  100% {
    transform: scale(1.1) translate(-5%, -5%);
  }
}

.animate-kenburns {
  animation: kenburns 5s ease-in-out infinite alternate;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fadeIn 2s ease-in-out;
}
