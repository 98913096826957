@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
  
  .scroll-icon {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    animation: bounce 2s infinite;
    display: flex;
    align-items: center;
  }
  
  .scroll-icon svg {
    width: 30px;
    height: 30px;
    fill: white;
  }
  .circle-cursor {
    cursor: url('/public//images/pump.png'), auto;
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .spinner {
    animation: spin 2s linear infinite;
  }
  
  